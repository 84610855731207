import { Constants } from "../../enum/Constants";

export class Address {
    private _inscriptionId: string;
    private _streetType: string;
    private _streetName: string;
    private _number: string;
    private _portal: string;
    private _block: string;
    private _stair: string;
    private _floor: string;
    private _door: string;
    private _postCode: string;
    private _foreignPostCode: string;
    private _municipality: number;
    private _province: number;
    private _foreignProvince: string;
    private _foreignMunicipality: string;
    private _countryCode: number;
    private _country: string;
    private _streetTypePrint: string;
    private _municipalityPrint: string;
    private _provincePrint: string;

    constructor(data: any) {
        this._inscriptionId = data.id ? data.id : '';
        this._streetType = data.streetType;
        this._streetName = data.streetName;
        this._number = data.number ? data.number : '';
        this._portal = data.portal;
        this._block = data.block;
        this._stair = data.stair;
        this._floor = data.floor;
        this._door = data.door;
        this._postCode = data.getFormatedPostCode || data.postCode;
        this._foreignPostCode = data.foreignPostCode;
        this._municipality = data.municipality;
        this._province = data.province;
        this._foreignProvince = data.foreignProvince;
        this._foreignMunicipality = data.foreignMunicipality;
        this._country = data.country;
        this._countryCode = Number(data.country);
        this._streetTypePrint = '';
        this._municipalityPrint = '';
        this._provincePrint = '';
    }

    public get inscriptionId() {
        return this._inscriptionId;
    }

    public set inscriptionId(inscriptionId: string) {
        this._inscriptionId = inscriptionId;
    }

    public get streetType() {
        return this._streetType;
    }

    public set streetType(streetType: string) {
        this._streetType = streetType;
    }

    public get streetName() {
        return this._streetName;
    }

    public set streetName(streetName: string) {
        this._streetName = streetName;
    }

    public get number() {
        return this._number;
    }

    public set number(number: string) {
        this._number = number;
    }

    public get portal() {
        return this._portal;
    }

    public set portal(portal: string) {
        this._portal = portal;
    }

    public get block() {
        return this._block;
    }

    public set block(block: string) {
        this._block = block;
    }

    public get stair() {
        return this._stair;
    }

    public set stair(stair: string) {
        this._stair = stair;
    }

    public get floor() {
        return this._floor;
    }

    public set floor(floor: string) {
        this._floor = floor;
    }

    public get door() {
        return this._door;
    }

    public set door(door: string) {
        this._door = door;
    }

    public get postCode() {
        return this._postCode;
    }

    public set postCode(postCode: string) {
        this._postCode = postCode;
    }

    public get foreignPostCode() {
        return this._foreignPostCode;
    }

    public set foreignPostCode(foreignPostCode: string) {
        this._foreignPostCode = foreignPostCode;
    }

    public get municipality() {
        return this._municipality;
    }

    public set municipality(municipality: number) {
        this._municipality = municipality;
    }

    public get province() {
        return this._province;
    }

    public set province(province: number) {
        this._province = province;
    }

    public get foreignProvince() {
        return this._foreignProvince;
    }

    public set foreignProvince(foreignProvince: string) {
        this._foreignProvince = foreignProvince;
    }

    public get foreignMunicipality() {
        return this._foreignMunicipality;
    }

    public set foreignMunicipality(foreignMunicipality: string) {
        this._foreignMunicipality = foreignMunicipality;
    }

    public get country() {
        return this._country;
    }

    public set country(country: string) {
        this._country = country;
    }

    public get countryCode() {
        return this._countryCode;
    }

    public set countryCode(countryCode: number) {
        this._countryCode = countryCode;
    }

    public get streetTypePrint() {
        return this._streetTypePrint;
    }
    public set streetTypePrint(streetTypePrint: string) {
        this._streetTypePrint = streetTypePrint;
    }

    public get municipalityPrint() {
        return this._municipalityPrint;
    }
    public set municipalityPrint(municipalityPrint: string) {
        this._municipalityPrint = municipalityPrint;
    }
    
    public get provincePrint() {
        return this._provincePrint;
    }
    public set provincePrint(provincePrint: string) {
        this._provincePrint = provincePrint;
    }
    
    public isForeign() {
        if (this.foreignPostCode) {
            this._postCode = null;
        }
    }

    public toServer() {
        return {
            inscriptionId: this._inscriptionId,
            address: {
                streetType: this._streetType,
                streetName: this._streetName,
                number: this._number,
                portal: this._portal,
                block: this._block,
                stair: this._stair,
                floor: this._floor,
                door: this._door,
                postCode: Number(this._postCode),
                foreignPostCode: this._foreignPostCode,
                municipality: this._municipality,
                province: this._province,
                country: this._countryCode,
                foreignProvince: this._foreignProvince,
                foreignMunicipality: this.foreignMunicipality
            }
        }
    }

    public toServerInscription() {
        return {
            streetType: this._streetType,
            streetName: this._streetName,
            number: this._number,
            portal: this._portal,
            block: this._block,
            stair: this._stair,
            floor: this._floor,
            door: this._door,
            postCode: this._postCode ? Number(this._postCode) : null,
            foreignPostCode: this._foreignPostCode,
            municipality: this._municipality,
            province: this._province,
            country: this._countryCode,
            foreignProvince: this._foreignProvince,
            foreignMunicipality: this.foreignMunicipality
        }
    }

    public updateAddress(address, streetTypes, provinces, municipalities, countries) {
        this._block = address.block;
        this._countryCode = Number(address.country);
        this._country = countries.find(element => element.key === address.country).value;
        this._door = address.door;
        this._floor = address.floor;
        this._foreignProvince = address.foreignProvince;
        this._foreignMunicipality = address.foreignMunicipality;
        this._municipality = address.municipality;
        this._number = address.number;
        this._portal = address.portal;
        this._postCode = address.getFormatedPostCode;
        this._foreignPostCode = address.foreignPostCode;
        this._province = address.province;
        this._stair = address.stair;
        this._streetName = address.streetName;
        this._streetType = address.streetType;
        this._municipalityPrint = this._countryCode === Constants.country.SPAIN_CODE ? municipalities.find(municipality => Number(municipality.id) === address.municipality).name : '';
        this._provincePrint = this._countryCode === Constants.country.SPAIN_CODE ? provinces.find(province => Number(province.id) === address.province).name : '';
        this._streetTypePrint = this._countryCode === Constants.country.SPAIN_CODE ? streetTypes.find(streetType => streetType.id === address.streetType).name : '';
        this.isForeign();
    }

    public updateStreetTypePrint(streetTypes) {
        if (this.streetType) {
            this.streetTypePrint = streetTypes.find(streetType => streetType.id === this.streetType).name;
        }
    }
}
