//LIBRERIES
import Vue from "../node_modules/vue/dist/vue.common";
import i18n from '../lang/index';
import { ApmVuePlugin } from "../node_modules/@elastic/apm-rum-vue/dist/lib/index";
import * as input from "../node_modules/saviafront/lib/js/compiled/md-input-module";
import * as InputService from "./services/InputService";
import * as select from "../node_modules/saviafront/lib/js/compiled/md-select-module.js";

//MODELS
import { App } from "../domain/App";
import { Process } from "../domain/entities/process/Process";
import { Entity } from "../domain/Entity";
import { Publication } from "../domain/Publication";
import { Cookie } from "../domain/Cookie";
import { ProcessStatus } from "../domain/enum/ProcessStatus";
import { ProcessPhases } from "../domain/enum/ProcessPhases";
import { ProcessType } from "../domain/enum/ProcessType";
import { OppositionType } from "../domain/enum/OppositionType";
import { Languages } from "../domain/enum/Languages";
import { Constants } from "../domain/enum/Constants";
import { Filter } from "../domain/entities/filter/Filter";
import { Filters } from '../domain/entities/filter/IndexFilters';
import { FilterTypes } from "../domain/enum/FilterTypes";
import moment from "../node_modules/moment/moment";

//APISERVICES
import { getProcessList, getEntityById, getMainFileById } from "../infrastructure/apiServices/process.service";
import { getLastPublicationsList } from "../infrastructure/apiServices/publications.service";
import { loadCommonData } from "./services/loadCommonData";
import orderBy from "lodash/orderBy";
import deburr from "lodash/deburr";
import { getProcessesConfigurationGroup } from "../infrastructure/apiServices/masterData.service";
import * as MunicipalitiesService from "./services/MunicipalitiesService";
import { getMasterDataCountries, getMasterDataStreetTypes, getMasterDataProvinces, getMasterDataAutonomousCommunity, getMasterDataNationalities, getMasterDataMunicipalities  } from "../infrastructure/apiServices/masterData.service";
import * as OrderSelect from "./services/OrderSelect";
import { Representative } from "../domain/Representative";

const app = new App();
(Vue as any).use(ApmVuePlugin, app.rumApm);

export const vm = new Vue({
  el: Constants.APP,
  i18n,
  data: {
    processList: [] as any[],
    app: app,
    headerConfig: {},
    availableLanguajes: {},
    processTypeSelected: app.params.get('type') ? app.params.get('type') : 0,
    imageClass: app.params.get('type') ? ProcessType.image[app.params.get('type')] : ProcessType.image[ProcessType.enum.CALL],
    isLoading: true,
    showToaster: app.params.get('certificateLogout') === 'true',
    modalVisible: false,
    ProcessPhases: ProcessPhases,
    OppositionType: OppositionType,
    Languages: Languages,
    FilterTypes: FilterTypes,
    inscriptionFile: null,
    urlToInscription: '',
    lastPublications: [],
    processSelectedId: '',
    isRepresentative: false,
    ProcessStatus,
    ProcessType,
    span: null,
    applicantData: null,
    representativeData: null, 
    provinces: {},
    countries: {},
    municipalities: [],
    autonomousCommunityList: {},
    streetTypes: {},
    loadingStreetTypes: false,
    spainCode: Constants.country.SPAIN_CODE,
    fiveZeros: Constants.postalCode.FIVE_ZEROS,
    filter: new Filter(),
    filters: Filters,
    cookie: new Cookie(),
    showMandatoryCookies: false,
    showAnalysisCookies: false,
    showModalConfigurateCookies: false,
    showServiceStopModal: false,
    dontShowAgainStopServiceModalAgain: false,
    serviceStopLocalStorageKey: 'dontShowServiceStopModal',
    serviceStopEndDate: moment('2024-05-15T17:00:00'),
    constants: Constants
  },
  computed: {
    pageTitle: function () {
      return this.$i18n.t(ProcessType.title[this.processTypeSelected]);
    },
    noNif: function () {
      return this.$i18n.t("lang.inscriptions.noNif");
    },
    currentLanguage: function () {
      return this.$i18n.locale;
    },
    anyFieldRequiredEmpty: function () {
      const applicantData = Object.values(this.applicantData);
      applicantData.pop();
      return !(applicantData.every((element) => element !== '') && this.representativeData.isValidForm());
    },
    isValidNif: function () {
      return InputService.isValidNie(this.applicantData.nif) || InputService.isValidNif(this.applicantData.nif);
    },
    orderedProcess() {
      return this.filter.filteredData(this.currentLanguage);
    },
    lastLogin() {
      return moment(app.profile.lastLogin[0]).format(app.formatDate) + ' ' + moment.utc(app.profile.lastLogin[0]).local().format('HH:mm:ss');
    }
  },
  methods: {
    saveCookies() {
      this.cookie.savePersonalizateCookies();
      this.showModalConfigurateCookies = false;
    },
    acceptAllCookies() {
      this.cookie.acceptAllCookies();
    },
    declineAllCookies() {
      this.cookie.declineAllCookies();
    },
    closeModalConfigurateCookies() {
      this.showModalConfigurateCookies = false;
      this.showMandatoryCookies = false;
      this.showAnalysisCookies = false;
    },
    closeStopServiceModal() {
      this.showServiceStopModal = false;
    },
    acceptStopServiceModal() {
      if (this.dontShowAgainStopServiceModalAgain) {
        const serviceStopData = { serviceStopNotificationReaded: this.dontShowAgainStopServiceModalAgain, expires: this.serviceStopEndDate };
        localStorage.setItem(this.serviceStopLocalStorageKey, JSON.stringify(serviceStopData));
      }
      this.showServiceStopModal = false;
    },
    checkModalServiceStopLocalStorage() {
      const serviceStopItem = localStorage.getItem(this.serviceStopLocalStorageKey);
      if (!serviceStopItem) {
        return;
      }
      const serviceStopItemData = JSON.parse(serviceStopItem);
      if (moment() > moment(serviceStopItemData.expires)) {
        localStorage.removeItem(this.serviceStopLocalStorageKey);
      }
    },
    getLastPublicationsListOrdered() {
      this.lastPublications = orderBy(this.lastPublications, ['date', lastPublication => deburr(lastPublication.title[this.currentLanguage]).toLowerCase()], ['desc', 'asc']);
    },
    changeLocal(language: string) {
      this.$i18n.locale = language;
      app.changeLanguaje(language);
      this.getLastPublicationsListOrdered();
    },
    getData: function () {
      let self = this;
      self.isLoading = true;
      Promise.all([getProcessesConfigurationGroup(), getProcessList(self.processTypeSelected)])
        .then((response: any) => {
          var processesConfigGroups = response[0].data;
          var processList = response[1].data;
          processList.forEach(function (element: any) {
            const process = new Process(element, processesConfigGroups);
            self.gettingEntityById(element.entityOepId, process);
            self.processList.push(process);
          });
          self.processList = orderBy(self.processList, ['year', process => deburr(process.title[self.currentLanguage]).toLowerCase()], ['desc', 'asc']);
          self.filter.setData(self.processList);
          self.filter.setFilterElements(self.filters);
          self.isLoading = false;
        }).catch((error) => {
          console.log(error);
          self.isLoading = false;
        });
    },
    getAsideData: function () {
      let self = this;
      self.isLoading = true;
      getLastPublicationsList(Constants.publications.AMOUNT_OF_PUBLICATIONS).then(function (response: any) {
        response.data.forEach(function (element: any) {
          self.lastPublications.push(new Publication(element));
        });
        self.getLastPublicationsListOrdered();
        self.isLoading = false;
      }).catch(function () {
        self.isLoading = false;
      });
    },
    gettingEntityById: function (id: string, process: Process) {
      getEntityById(id).then(function (response: any) {
        let entity: Entity = new Entity(response.data.id, response.data.name);
        process.entity = entity;
      }).catch((error) => {
        console.log(error);
      });
    },
    openModal: function (id: string) {
      this.processSelectedId = id;
      this.urlToInscription = `inscription.html?id=${id}`;
      let self = this;
      this.buildUrlToInscription(id)
        .then(function (response: any) {
          if (response.data) {
            self.inscriptionFile = app.requestTo + response.data.url + "?origin=" + app.origin;
          }
          self.modalVisible = true;
        }).catch((error) => {
          console.log(error);
        });
    },
    buildUrlToInscription: function (id: string) {
      return getMainFileById(id);
    },
    closeModal: function () {
      this.modalVisible = false;
      this.inscriptionFile = '';
      this.urlToInscription = '';
      this.processSelectedId = '';
      this.isRepresentative = false;
      this.applicantData = null;
    },
    openRepresent: function () {
      this.isRepresentative = true;
      this.applicantData = {
        nif: '',
        name: '',
        firstSurname: '',
        secondSurname: '',
      }
      this.representativeData = new Representative({
        email: '',
        address: {
          country: this.spainCode,
          province: '',
          foreignProvince: '',
          municipality: '',
          foreignMunicipality: '',
          streetType: '',
          streetName: '',
          number: '',
          portal: '',
          block: '',
          stair: '',
          floor: '',
          door: '',
          postCode: '',
          foreignPostCode: '',
        }
      });
    },
    refreshStreeTypes() {
      this.loadingStreetTypes = true;
      this.streetTypes = null;
      getMasterDataStreetTypes().then((responseDataStreetTypes: any) => {
        this.streetTypes = OrderSelect.prepareDataNotArray(responseDataStreetTypes.data);
        this.loadingStreetTypes = false;
        if (this.$refs.representativeForm) {
          this.$nextTick(() => {
            InputService.checkValueAndUpdateInput(this.$refs.representativeForm);
          });
        }
      });
    },
    changedRepresentativeAddressCountry() {
      this.$nextTick(() => {
        InputService.checkValueAndUpdateInput(this.$refs.representativeForm);
        this.representativeData.address.countryCode = Number(this.representativeData.address.country);
      });
    },
    changedProvince() {
      this.representativeData.address.municipality = null;
      if (this.representativeData.address.province) {
        this.$nextTick(() => {
          this.municipalities = [];
          getMasterDataMunicipalities(this.representativeData.address.province)
            .then((municipalities: any) => {
              this.municipalities = MunicipalitiesService.setMunicipalities(municipalities.data, this.representativeData.address.municipality);
            });
        });
      }
    },
    getMasterData() {
      const self = this;
      getMasterDataCountries().then((responseCountries: any) => self.countries = responseCountries.data);
      getMasterDataAutonomousCommunity().then((responseAutonomousCommunity: any) => self.autonomousCommunityList = OrderSelect.prepareDataNotArray(responseAutonomousCommunity.data));
      getMasterDataStreetTypes().then((responseDataStreetTypes: any) => self.streetTypes = OrderSelect.prepareDataNotArray(responseDataStreetTypes.data));
      getMasterDataProvinces().then((responseProvinces: any) => self.provinces = OrderSelect.prepareDataNotArray(responseProvinces.data));
      getMasterDataNationalities().then((responseNationalities: any) => { self.nationalities = responseNationalities.data });
    },
    onlyNumber: function ($event: any, strict?: boolean) {
      let keyCode = ($event.which ? $event.which : $event.keyCode);
      if ((keyCode < Constants.keyCodes.ZERO_KEY_CODE || keyCode > Constants.keyCodes.NINE_KEY_CODE) && strict ||
        (keyCode < Constants.keyCodes.ZERO_KEY_CODE || keyCode > Constants.keyCodes.NINE_KEY_CODE) && keyCode !== Constants.keyCodes.DOT_KEY_CODE && keyCode !== Constants.keyCodes.COMMA_KEY_CODE) {
        $event.preventDefault();
      }
    },
    onSelectFocus($event: any) { select.onSelectFocus($event.target) },
    onSelectBlur($event: any) {
      select.onSelectBlur($event.target);
    },
    onInputFocus($event: any) {
      input.onInputFocus($event.target);
    },
    onInputBlur($event: any) {
      const elementNode = $event.target;
      input.onInputBlur(elementNode);
      if (!elementNode.validity.valid) {
        return;
      }
      InputService.validateFormRequired(elementNode.parentNode);
    },
    validateForm: function () {
      let self = this;
      return InputService.validateFormRequired(self.$refs.representativeForm);
    },
    goToInscription: function () {
      if (!this.validateForm()) {
        return;
      }
      const representativeToServer = this.representativeData.toServer();
      const representativeEncoded = encodeURIComponent(JSON.stringify(representativeToServer));
      location.href = 'inscription.html?id=' + this.processSelectedId + '&type=' + this.processTypeSelected + '&nif=' + this.applicantData.nif.toUpperCase() + '&firstSurname=' + this.applicantData.firstSurname + '&secondSurname=' + this.applicantData.secondSurname +'&name=' + this.applicantData.name + '&representative=' + representativeEncoded;
    },
    goToDetail: function (id: string) {
      location.href = "processDetail.html?id=" + id + '&type=' + this.processTypeSelected;
    },
    getAllData() {
      this.getData();
      this.getAsideData();
      this.getMasterData();
      this.app.getProfile();
    },
  },
  created() {
    this.span = this.$apm.startSpan('component-index', 'custom');
  },
  mounted() {
    this.span && this.span.end();
    loadCommonData(this, app, i18n);
    this.checkModalServiceStopLocalStorage();
    // this.showServiceStopModal = this.app.isServiceStopActive && (!localStorage.getItem(this.serviceStopLocalStorageKey));
    if (this.showToaster) {
      setTimeout(() => {
        this.showToaster = false;
      }, 8000);
    }
  }
});




